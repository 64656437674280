@import "../../styles/static";

header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width:$breakpoint-md-min) {
    width: 750px;
  }

  @media (min-width:$breakpoint-lg-min) {
    width: 970px;
  }

  @media (min-width:$breakpoint-xl) {
    width: 1170px;
  }

  margin: 0 auto;
  overflow: auto;
  background: #fff;
  position: fixed;
  z-index: 5;
  left: 0;
  right: 0;
  top: 0;
  border-bottom: 1px solid rgba(0,0,0,.15);

  @media only screen and (max-width: $breakpoint-md-max) {
    padding: 0;
  }


  .header__title {
    display: flex;
    margin-block-start: 1em;
    margin-block-end: 1em;
    align-items: center;

    .logo {
      width: 36px;
    }

    .name {
      margin-left: 12px;
      font-weight: bold;
      color: $color-primary-text;
    }
  }


  .nav-list {
    float: right;
    list-style: none;
    display: table;

    @media only screen and (max-width: $breakpoint-md-max) {
      padding-inline-start: 0;
      margin-block-start: 0;
      margin-block-end: 0;
      margin: 16px auto;
      float: none;
    }

    li {
      position: relative;
      padding: 0 20px;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      font-size: 16px;

      @media only screen and (max-width: $breakpoint-md-max) {
        font-size: 12px;
        padding: 0 8px;
      }

      .nav__link {
        color: $color-secondary-text;
  
        &.active {
          font-weight: bold;
          &:hover {
            color: $color-secondary-text;
          }
        }
  
        &:hover {
          color: $color-blue;
        }
      }
    }
  }
}