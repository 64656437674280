@import "../../styles/static";

.shimmer {
    animation : shimmer 2s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #F2F2F2 36%);
    background-size: 1000px 100%;
}

@keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
}