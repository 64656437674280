.form-section {
  margin: 12px 0;
}

.label-input {
  font-size: 14px;
  color: $color-primary-text;
}

.label-error {
  font-size: 14px;
  color: #D8000C;
  margin: 0 0 12px;
}

input[type=text], textarea {
  width: 100%;
  padding: 12px 16px;
  margin: 8px 0;
  display: inline-block;
  border: 0;
  background: #F2F2F2;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 14px;
  color: $color-primary-text;
  font-family: 'DM Sans', sans-serif;

  &:focus {
    outline-width: 0;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #C2C2C2;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: #C2C2C2;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: #C2C2C2;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: #C2C2C2;
  }
}

input[type=submit] {
  width: 100%;
  cursor: pointer;
  outline: none;
}

/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 2px solid $color-primary-text;
  border-radius: 4px;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: $color-blue;
  border: 2px solid $color-blue;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.select-dropdown {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  vertical-align: top;
  align-items: flex-start;
  cursor: pointer;
  color: #828282;

  &__container {
    position: relative;
    max-width: 100%;
  }
}

.select-dropdown__content {
  display: block;
  width: 100%;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .label-wrapper {
    display: block;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 18px 0px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 48px;
    line-height: 1.6;
    padding: 12px;
    max-width: 100%;
  }

  &.is-open .selected-label:after {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-top: 9px;
  }

  .selected-label {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
    &:after {
      content:'';
      display: inline-block;
      width: 10px;
      height: 10px;
      vertical-align: middle;
      float: right;
      margin-top: 4px;
      border: 1px solid black;
      border-width: 0 1px 1px 0;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      margin-right: 2px;
    }
  }

  .box-positioner {
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 100%;
    z-index: 2;
  }

  &.is-open .options-box {
    display: block;
  }
  
  .options-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    background-color: #fff;
    display: none;
  }

  .scroll-area {
    height: auto;
    max-height: 180px;
    overflow: auto;
  }

  .scroll-area * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .item {
    padding: 12px 12px 12px 0px;
    margin: 0 16px;
    cursor: pointer;
    align-items: center;
    font-size: 14px;
    color: #828282;
    border-bottom: 1px solid #F2F2F2;

    &--all {
      background: #F2F2F2;
      padding: 12px 16px 12px 16px;
      margin: 0;
      border-bottom: 0;
      text-align: center;
    }

    &:last-of-type {
      border-bottom: 0;
    }
    .notes {
      color: #9FA6B0;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  .dropdown-note {
    font-size: 11px;
    color: rgba(0, 0, 0, 0.38);
    text-align: center;
    padding: 7px 0;
    border-top: 1px solid #e0e0e0;
  }
}