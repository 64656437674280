@import "./styles/base.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'DM Sans', sans-serif;
  color: #060606;
}

article {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

main {
  flex: 1 0 auto;
}

.container {
  max-width: 970px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width:$breakpoint-lg-max) {
    padding: 0 32px;
  }
  
  @media (max-width:$breakpoint-md-max) {
    max-width: 768px;
    margin: initial;
    padding: 0 16px;
  }

  &--mobile {
    margin-top: 64px;
  }
}

.clearfix:after,
.clearfix:before {
	content: " ";
	display: table;
}

.clearfix:after {
	clear: both;
}

:after,
:before {
	box-sizing: border-box;
}

a, .link {
  text-decoration: none;
  color: #57A3BD;
  cursor: pointer;

  &--button {
    border: 0;
    background: transparent;
    padding: 0;
    font-family: 'DM Sans', sans-serif;
  }
 
  &:hover {
    color: #96D5EB;
  }
}

.slider {
  outline: none;

  &-control-centerleft, &-control-centerright {
    top: 45% !important;
  }

  &-dots {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: -16px;

    li {
      display: inline-block;
      height: 12px;
      width: 12px;
      background-color: #F2F2F2;
      margin: 0px 10px;
      border-radius: 50%;

      &.active {
        background-color: #48D2DA;
      }
    }
  }
}

#SRLLightbox {
  z-index: 20;
}

.tooltip {
  position: relative;
  cursor: pointer;
  line-height: 0;
  
  @media (max-width:$breakpoint-md-max) {
    cursor: initial;
  }

  .tooltiptext {
    @media (max-width:$breakpoint-md-max) {
      display: none;
    }
    visibility: hidden;
    font-size: 12px;
    width: 200px;
    background-color: $color-white;
    color: $color-secondary-text;
    border-radius: 8px;
    line-height: 1.2;
    padding: 12px;
    box-shadow: rgba(49, 53, 59, 0.12) 0px 1px 6px 0px;
    position: absolute;
    z-index: 1;
    top: 160%;
    left: 50%;
    margin-left: -60px;

    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 27%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $color-white transparent;
    }
  }

  &:hover {
    .tooltiptext {
      visibility: visible;

      @media (max-width:$breakpoint-md-max) {
        display: none;
      }
    }
  }
}

.divider {
  border: 4px solid #f3f4f5;
}

.notes {
  background: #F8F8F8;
  padding: 12px;
  border-radius: 8px;
  font-size: 12px;
  color: $color-secondary-text;
}