@import "../../styles/static";

.container__home {
    max-width: 970px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 120px;
  
    @media (max-width:$breakpoint-lg-max) {
      margin-top: 96px;
      padding: 0 32px;
    }
  
    @media (max-width:$breakpoint-md-max) {
      max-width: 768px;
      padding: 0 16px;
    }
}

.home__hero {
  margin: 0 auto;

  .title {
    display: flex;
    align-items: center;
  }

  .hero__logo {	
    width: 40px;	
    height: 40px;	
    margin-right: 8px;	

    @media (max-width: 350px) {	
      width: 32px;	
      height: 32px;	
      margin-right: 4px;	
    }	
  }

  .hero__title {
    font-size: 40px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin: 16px 0;
    
    @media only screen and (max-width: $breakpoint-md-max) {
      font-size: 24px;
      margin: 8px 0;
    }

    @media (max-width: 350px) {
      font-size: 22px;
    }
  }

  .hero__desc {
    font-size: 18px;
    color: $color-secondary-text;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 1.8;

    @media only screen and (max-width: $breakpoint-md-max) {
      font-size: 14px;
      margin-top: 16px;
    }
  }
}

.home__steps {
  display: flex;
  justify-content: space-between;
  margin: 56px auto 140px;

  @media (max-width:$breakpoint-md-max) {
    margin: 32px 0;
    display: block;
  }

  .steps-section__img {
    width: 35%;
    position: relative;

    @media (max-width:$breakpoint-md-max) {
      width: 100%;
      text-align: center;
      margin-bottom: -18px;
    }

    .illustration__wrapper {
      position: absolute;
      left: 40px;

      @media only screen and (max-width: $breakpoint-lg-max) {
        left: 0;
      }
    }

    .icon__phone {
      height: 450px;

      @media (max-width:$breakpoint-md-max) {
        height: 300px;
      }
    }

    .icon__steps {
      position: absolute;

      &--one {
        width: 360px;
        top: 14%;
        left: -30%;

        @media only screen and (max-width: $breakpoint-lg-max) {
          width: 300px;
          top: 20%;
          left: -14%;
        }

        @media (max-width:$breakpoint-md-max) {
          width: 230px;
          top: 15%;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          text-align: center;
        }

        @media (max-width: 350px) {
          width: 180px;
          top: 20%;
        }
      }

      &--two {
        width: 300px;
        top: 20%;
        left: -16%;

        @media (max-width:$breakpoint-md-max) {
          width: 220px;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          text-align: center;
        }

        @media (max-width: 350px) {
          width: 180px;
          top: 25%;
        }
      }

      &--three-caps {
        width: 175px;
        top: 25%;
        left: 13%;

        @media (max-width:$breakpoint-md-max) {
          width: 100px;
          top: 23%;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          text-align: center;
        }
      }

      &--three-tag1 {
        width: 70px;
        top: 60%;
        left: 12%;

        @media (max-width:$breakpoint-md-max) {
          width: 50px;
          top: 58%;
          left: 28%;
        }
      }

      &--three-tag2 {
        width: 126px;
        top: 65%;
        left: 13%;

        @media (max-width:$breakpoint-md-max) {
          width: 96px;
          top: 64%;
          left: 28.5%;
        }
      }

      &--four {
        width: 300px;
        top: 15%;
        left: -16%;

        @media (max-width:$breakpoint-md-max) {
          width: 200px;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          text-align: center;
        }
      }
    }
  }

  .steps__title {
    font-size: 32px;
    margin: 0 0 24px;

    @media (max-width:$breakpoint-lg-max) {
      position: initial;
      font-size: 24px;
    }

    @media (max-width:$breakpoint-md-max) {
      font-size: 20px;
      margin-bottom: 8px;
    }
  }

  .steps-section__content {
    width: 60%;
  }

  .steps__list {
    margin: 0;
    padding-left: 0;
    list-style: none;

    &--mobile {
      overflow: auto;
    }

    &--item {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        display: block;
        height: calc(100% - 56px);
        left: 20px;
        bottom: 8px;
        border-left: 1px dashed $color-grey;
      }

      &:last-child {
        .list__desc {
          margin-bottom: 0;
        }

        &::after {
          display: none;
        }
      }
    }

    .list__number {
      display: inline-block;
      cursor: pointer;
      border-radius: 50%;
      border: 1px solid $color-grey;
      height: 40px;
      width: 40px;
      line-height: 40px;
      background-color: #fff;
      font-size: 20px;
      color: $color-grey;
      text-align: center;
      vertical-align: top;
      margin-right: 24px;

      &.active {
        border-color: $color-blue;
        background-color: $color-blue;
        color: $color-white;
      }
    }

    .list__desc {
      display: inline-block;
      width: calc(100% - 72px);
      margin-bottom: 22px;

      .desc__title {
        cursor: pointer;
        font-size: 20px;
        margin: 8px 0;
        color: $color-secondary-text;

        &.active {
          color: $color-primary-text;
        }
      }

      .desc__content {
        font-size: 16px;
        line-height: 1.5;
        margin: 0;
        color: $color-secondary-text;
        overflow: hidden;
        max-height: 0px;
        transition: max-height .6s ease-out;

        &.active {
          max-height: 300px;
          transition: max-height .6s ease-in;
        }
      }
    }
  }

  .steps__arrow {
    width: 48px;
    height: 48px;
    
    &--prev {
      margin-left: -4px;
    }

    &--next {
      margin-right: -4px;
    }
  }
}

.home__business {
  margin-bottom: 80px;

  @media (max-width:$breakpoint-md-max) {
    margin-top: 64px;
  }

  .business__title {
    color: $color-primary-text;
    font-size: 32px;
    margin-bottom: 24px;
    text-align: center;

    @media (max-width:$breakpoint-lg-max) {
      font-size: 24px;
    }

    @media (max-width:$breakpoint-md-max) {
      font-size: 20px;
    }
  }

  .business__filter {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 18px 0px;
    border-radius: 8px;
    padding: 16px 32px;
    display: flex;

    @media (max-width:$breakpoint-md-max) {
      padding: 12px;
      border-radius: 12px;
    }

    .filter__category {
      margin: 0;
      padding-left: 0;
      list-style: none;
      overflow-y: hidden;
      overflow-x: scroll;
      white-space: nowrap;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* IE 10+ */

      &::-webkit-scrollbar {
        display: none;
        width: 0px;
        background: transparent; /* Chrome/Safari/Webkit */
      }

      li {
        display: inline-block;
        padding: 0 16px;

        &:first-child {
          padding-left : 0;
        }

        &:last-child {
          padding-right : 0;
        }

        .category-item {
          color: $color-secondary-text;
          border: 0;
          cursor: pointer;
          font-size: 14px;
          padding: 0;
          outline: none;
          background-color: transparent;
          font-family: 'DM Sans', sans-serif;
    
          &:hover {
            color: $color-blue;
          }
    
          &.active {
            font-weight: bold;

            &:hover {
              color: $color-secondary-text;
            }
          }
        }
      }
    }
  }

  .business__content {
    display: flex;
    flex-wrap: wrap;
    margin: 28px 0px;

    @media (max-width:$breakpoint-md-max) {
      display: block;
      margin: 0px 0px 24px;
    }

    .item {
      flex-basis: calc(33.33% - 24px);
      margin: 0px 12px 24px;
      min-width: 0;

      @media (max-width:$breakpoint-md-max) {
        display: block;
        margin: 32px 0 0;
      }
    }
  }

  .business__empty {
    text-align: center;
    margin: 72px 0 150px;

    .emoji {
      font-size: 40px;

      @media (max-width:$breakpoint-md-max) {
        font-size: 32px;
      }
    }

    .title {
      font-size: 24px;
      margin: 16px 0 8px;
      color: $color-primary-text;

      @media (max-width:$breakpoint-md-max) {
        font-size: 18px;
      }

      @media (max-width:350px) {
        font-size: 14px;
      }
    }

    p {
      margin: 0;
      font-size: 16px;
      color: $color-secondary-text;

      @media (max-width:$breakpoint-md-max) {
        font-size: 14px;
      }

      @media (max-width:350px) {
        font-size: 12px;
      }
    }

    .button {
      margin-top: 24px;
    }
  }

  .business__pagination {
    text-align: center;
    color: $color-secondary-text;
    font-size: 18px;

    .btn__prev {
      margin-right: 16px;
      border: 1px solid $color-grey;
      background-color: $color-white;
      color: $color-secondary-text;
    }

    .btn__next {
      margin-left: 16px;
      background: $color-blue;
      color: $color-white;
      border: 0;
    }

    .btn__prev, .btn__next {
      outline: none;
      cursor: pointer;
      border-radius: 12px;
      padding: 12px;
      font-size: 14px;

      @media only screen and (max-width: $breakpoint-md-max) {
        font-size: 12px;
      }

      &.hidden { 
        display: none;
      }
    }
  }

  .business__load {
    text-align: center;
  }
}