/* Base Color */
$color-blue-lighter: #48D2DA;
$color-blue-light: #42BABF;
$color-blue: #3EADB1;
$color-blue-dark: #34A2A6;

$color-primary-text: #060606;
$color-secondary-text: #828282;

$color-grey-dark: #9FA6B0;
$color-grey: #C2C2C2;
$color-grey-light: #F2F2F2;

$color-white: #FFFFFF;