@import "../../styles/static";

.business__card {
    box-shadow: rgba(49, 53, 59, 0.12) 0px 1px 6px 0px;
    border-radius: 8px;
    position: relative;
    color: $color-secondary-text;

    &.loading {
        overflow: auto;
    }

    .bc__image {
        position: relative;
        height: 180px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: $color-grey-light;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        .img__content {
            position: absolute;
            bottom: 10px;
            left: 10px;
        }
    }

    .ribbon {
        color: $color-white;
        z-index: 1;
        width: 134px;
        height: 32px;
        line-height: 32px;
        padding-left: 15px;
        position: absolute;
        left: -8px;
        top: 16px;
        font-size: 10px;
        background: $color-grey-dark;

        &:before, &:after {
            content: "";
            position: absolute;
        }

        &:before {
            height: 0;
            width: 0;
            top: -8.5px;
            left: 0.1px;
            border-bottom: 8px solid $color-secondary-text;
            border-left: 8px solid transparent;
        }
        
        &:after {
            height: 0;
            width: 0;
            right: -16px;
            border-top: 16px solid transparent;
            border-bottom: 16px solid transparent;
            border-left: 16px solid $color-grey-dark;
        }
    }

    .bc__content {
        padding: 10px 12px;

        .title {
            color: $color-secondary-text;
            margin: 0 0 4px;
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .desc {
            color: $color-secondary-text;
            font-size: 12px;
            margin: 0;
            line-height: 2;
        }
    }
}