@import "../../styles/static.scss";

.bs-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  overflow: hidden;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.2s linear, z-index 0.1s linear;

  &.clear {
    background: none;
  }

  &--show {
    opacity: 0.88;
    z-index: 50;
  }
}

.bottom-sheets {
  position: fixed;
  bottom: -700px;
  left: 0;
  width: 100%;
  max-height: calc(100% - 49px);
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 -1px 7px 0 rgba(0, 0, 0, 0.15);
  z-index: -1;
  box-sizing: border-box;
  transition: bottom 0.5s ease-out, z-index 0.5s ease-out;

  &--show {
    z-index: 60;
    bottom: 0;
    transition: bottom 0.3s ease-out, z-index 0.3s ease-out;
  }

  &__title {
    position: relative;
    padding: 8px 16px;
    font-size: 1.14286rem;
    font-weight: 600;
    line-height: 32px;
    color: $color-primary-text;
    border-bottom: 1px solid #E0E0E0;
    display: flex;

    &-close {
      background-image: url('./assets/ic-close.svg');
      background-position: 0 center;
      background-size: 18px;
      background-repeat: no-repeat;
      min-width: 18px;
    }
  }

  &__content {
    padding: 16px;
    max-height: 100%;
    overflow-y: auto;

    .label {
      font-size: 14px;
      color: $color-primary-text;
    }
  }
}